import { isBrowser } from "./browser";

const pushDataLayerEvent = (event: string, props: { [key: string]: any } = {}) => {
    if (isBrowser() && window.dataLayer) {
        window.dataLayer.push({ event, ...props });
    }
};

export {
    pushDataLayerEvent
};
