import {
    useEffect, useRef, useState, PropsWithChildren
} from "react";
import styles from "./AnimationContainer.module.scss";

interface AnimationContainerProps { }

const animationName = "fade-in-up";

const AnimationContainer = (props: PropsWithChildren<AnimationContainerProps>) => {
    const { children, ...restProps } = props;
    const containerRef = useRef<HTMLDivElement>(null);
    const [animationCSS, setAnimationCSS] = useState<string>(styles.animationContainer);
    const [animationState, setAnimationState] = useState<string>("waiting");
    useEffect(() => {
        if (containerRef.current === null) {
            return;
        }
        const containerElement = containerRef.current;

        const buildThresholdList = () => {
            const thresholds = [];
            const numSteps = 20;

            for (let i = 1.0; i <= numSteps; i += 1) {
                const ratio = i / numSteps;
                thresholds.push(ratio);
            }

            thresholds.push(0);
            return thresholds;
        };

        const handleIntersect = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach((entry) => {
                if (animationState === "finished") {
                    observer.disconnect();
                }
                if (animationState === "waiting" && entry.intersectionRatio > 0.5) {
                    containerElement.classList.add(animationName);
                    setAnimationCSS(`${styles.animationContainer} ${styles.animation}`);
                    setAnimationState("finished");
                }
            });
        };

        const options = {
            root: null,
            rootMargin: "0px",
            threshold: buildThresholdList()
        };

        const observer = new IntersectionObserver(handleIntersect, options);
        observer.observe(containerElement);
    }, []);

    return (
        <div ref={containerRef} className={animationCSS} {...restProps}>
            <div className={styles.animationContainerChildren}>
                {children}
            </div>
        </div>
    );
};

export {
    AnimationContainer
};
